/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.c-card{
    height: 30px;
    background-color:#a6d3db;
    text-align: center;
    vertical-align: middle;
    margin: 2px;
    opacity: 1;
    cursor: pointer;
}

.main{
    align-self: center;
    background-color: rgb(211, 228, 247);
    border-radius: 10px;
}

.active-card{
    height: 30px;
    background-color: #517b9c;
    text-align: center;
    vertical-align: middle;
    margin: 2px;
    opacity: 1;
    cursor: pointer;
    color: white;
}

.input{
    text-align: center;
}